import { CollectionName } from "./collection.types";

export const routePathsWithPermission = [
  "/app/contract-offers",
  "/app/contract-offers/active",
  "/app/contract-offers/overview/:contractOfferId",
  "/app/contract-offers/archived",
  "/app/contract-offers/create",
  "/app/contracts",
  "/app/contracts/:contractId",
  "/app/contracts/:contractId/contract",
  "/app/contracts/:contractId/costs",
  "/app/contracts/:contractId/economy",
  "/app/contracts/:contractId/budget",
  "/app/contracts/:contractId/budget-adjustment",
  "/app/contracts/:contractId/incomes",
  "/app/contracts/:contractId/insights",
  "/app/contracts/:contractId/invoiceplans",
  "/app/contracts/:contractId/invoiceplans/:invoicePlanId",
  "/app/contracts/:contractId/invoiceplans/create",
  "/app/contracts/:contractId/invoices",
  "/app/contracts/:contractId/invoices/customer",
  "/app/contracts/:contractId/invoices/customer/:id",
  "/app/contracts/:contractId/invoices/planned",
  "/app/contracts/:contractId/invoices/planned/:id",
  "/app/contracts/:contractId/linkedinvoicedata",
  "/app/contracts/:contractId/report",
  "/app/contracts/:contractId/subcontracts",
  "/app/contracts/:contractId/subcontracts/:subcontractId",
  "/app/contracts/:contractId/supplierinvoices",
  "/app/contracts/contracttypes",
  "/app/contracts/contracttypes/:contractTypeId/overview",
  "/app/contracts/contracttypes/create",
  "/app/contracts/create",
  "/app/contracts/create/:contractOfferId",
  "/app/contracts/invoiceplantemplates",
  "/app/contracts/invoiceplantemplates/:invoicePlanTemplateId",
  "/app/dashboard",
  "/app/mailgun",
  "/app/operations/accounts",
  "/app/operations/customers",
  "/app/operations/customers/:id",
  "/app/operations/customers/create",
  "/app/operations/invoices",
  "/app/operations/invoices/customer",
  "/app/operations/invoices/customer/:id",
  "/app/operations/invoices/planned",
  "/app/operations/invoices/planned/:id",
  "/app/operations/linkedinvoicedata",
  "/app/operations/poc",
  "/app/operations/supplierinvoices",
  "/app/operations/suppliers",
  "/app/operations/transferownerships",
  "/app/product-and-plans/articles",
  "/app/product-and-plans/articles/:id",
  "/app/product-and-plans/articles/create",
  "/app/project-tracker",
  "/app/respond/contract-offer/:id",
  "/app/insights/company",
  "/app/insights/contract",
  "/app/insights/reports",
  "/app/setup/accounting",
  "/app/setup/activity-logs",
  "/app/setup/custom-fields",
  "/app/setup/dimensions",
  "/app/setup/file-drive",
  "/app/setup/indices",
  "/app/setup/indices/create",
  "/app/setup/integrations",
  "/app/setup/members",
  "/app/setup/number-series",
  "/app/setup/overview",
  "/app/setup/reports",
  "/app/superadmin",
  "/app/superadmin/companies",
  "/app/superadmin/configurations",
  "/app/superadmin/crons",
  "/app/superadmin/developers",
  "/app/superadmin/email-templates",
  "/app/superadmin/languages",
  "/app/superadmin/mailgun-logs",
  "/app/superadmin/translations",
  "/app/superadmin/warnings",
] as const;

const routesNoPermission = [
  "*", // This is a catch all route, it should be last in the list.
  "/",
  "/app",
  "/app/invite/:id",
  "/app/profile",
  "/documentation",
  "/documentation-external",
  "/login",
  "/newpassword",
] as const;

export type RoutePath =
  | (typeof routePathsWithPermission)[number]
  | (typeof routesNoPermission)[number];

export interface AccessEndpointMap {
  endpoint: RoutePath;
  demands?: {
    BKIntegration?: boolean;
    collectionTargets?: CollectionName[];
    superAdmin?: boolean;
  };
}

export const accessEndpointMaps: AccessEndpointMap[] = [
  {
    endpoint: "/app/contract-offers",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/contract-offers/active",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/contract-offers/overview/:contractOfferId",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/contract-offers/archived",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/contract-offers/create",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/contracts",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId/contract",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId/costs",
    demands: { collectionTargets: ["costs"] },
  },
  {
    endpoint: "/app/contracts/:contractId/economy",
    demands: { collectionTargets: ["budgets"] },
  },
  {
    endpoint: "/app/contracts/:contractId/incomes",
    demands: { collectionTargets: ["incomes"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoices",
    demands: { collectionTargets: ["customer_invoices", "planned_invoices"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoices/customer",
    demands: { collectionTargets: ["customer_invoices"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoices/customer/:id",
    demands: { collectionTargets: ["customer_invoices"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoices/planned",
    demands: { collectionTargets: ["planned_invoices"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoices/planned/:id",
    demands: { collectionTargets: ["planned_invoices"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoiceplans",
    demands: { collectionTargets: ["invoiceplans"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoiceplans/create",
    demands: { collectionTargets: ["invoiceplans"] },
  },
  {
    endpoint: "/app/contracts/:contractId/invoiceplans/:invoicePlanId",
    demands: { collectionTargets: ["invoiceplans"] },
  },
  {
    endpoint: "/app/contracts/:contractId/insights",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/contracts/:contractId/linkedinvoicedata",
    demands: { collectionTargets: ["linkedinvoicedata"] },
  },
  {
    endpoint: "/app/contracts/:contractId/report",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId/subcontracts",
    demands: { collectionTargets: ["subcontracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId/subcontracts/:subcontractId",
    demands: { collectionTargets: ["subcontracts"] },
  },
  {
    endpoint: "/app/contracts/:contractId/supplierinvoices",
    demands: { collectionTargets: ["supplier_invoices"] },
  },
  {
    endpoint: "/app/contracts/contracttypes",
    demands: { collectionTargets: ["contracttypes"] },
  },
  {
    endpoint: "/app/contracts/contracttypes/:contractTypeId/overview",
    demands: { collectionTargets: ["contracttypes"] },
  },
  {
    endpoint: "/app/contracts/contracttypes/create",
    demands: { collectionTargets: ["contracttypes"] },
  },
  {
    endpoint: "/app/contracts/create",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/create/:contractOfferId",
    demands: { collectionTargets: ["contracts"] },
  },
  {
    endpoint: "/app/contracts/invoiceplantemplates",
    demands: { collectionTargets: ["invoiceplan_templates"] },
  },
  {
    endpoint: "/app/contracts/invoiceplantemplates/:invoicePlanTemplateId",
    demands: { collectionTargets: ["invoiceplan_templates"] },
  },
  {
    endpoint: "/app/dashboard",
    demands: {},
  },
  {
    endpoint: "/app/mailgun",
    demands: { collectionTargets: ["mailgun_logs"] },
  },
  {
    endpoint: "/app/operations/accounts",
    demands: { collectionTargets: ["accounts"] },
  },
  {
    endpoint: "/app/operations/customers",
    demands: { collectionTargets: ["customers"] },
  },
  {
    endpoint: "/app/operations/customers/create",
    demands: { collectionTargets: ["customers"] },
  },
  {
    endpoint: "/app/operations/customers/:id",
    demands: { collectionTargets: ["customers"] },
  },
  {
    endpoint: "/app/operations/invoices",
    demands: { collectionTargets: ["customer_invoices", "planned_invoices"] },
  },
  {
    endpoint: "/app/operations/invoices/customer",
    demands: { collectionTargets: ["customer_invoices"] },
  },
  {
    endpoint: "/app/operations/invoices/customer/:id",
    demands: { collectionTargets: ["customer_invoices"] },
  },
  {
    endpoint: "/app/operations/invoices/planned",
    demands: { collectionTargets: ["planned_invoices"] },
  },
  {
    endpoint: "/app/operations/invoices/planned/:id",
    demands: { collectionTargets: ["planned_invoices"] },
  },
  {
    endpoint: "/app/operations/linkedinvoicedata",
    demands: { collectionTargets: ["linkedinvoicedata"] },
  },
  {
    endpoint: "/app/operations/poc",
    demands: { collectionTargets: ["contracts", "companies"] },
  },
  {
    endpoint: "/app/operations/supplierinvoices",
    demands: { collectionTargets: ["supplier_invoices"] },
  },
  {
    endpoint: "/app/operations/suppliers",
    demands: { collectionTargets: ["suppliers"] },
  },
  {
    endpoint: "/app/operations/transferownerships",
    demands: { collectionTargets: ["contracts", "companies"] },
  },
  {
    endpoint: "/app/product-and-plans/articles",
    demands: { collectionTargets: ["articles"] },
  },
  {
    endpoint: "/app/product-and-plans/articles/create",
    demands: { collectionTargets: ["articles"] },
  },
  {
    endpoint: "/app/product-and-plans/articles/:id",
    demands: { collectionTargets: ["articles"] },
  },
  {
    endpoint: "/app/project-tracker",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/respond/contract-offer/:id",
    demands: { collectionTargets: ["contract_offers"] },
  },
  {
    endpoint: "/app/insights/company",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/insights/contract",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/insights/reports",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/setup/accounting",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/setup/activity-logs",
    demands: { collectionTargets: ["activity_logs"] },
  },
  {
    endpoint: "/app/setup/custom-fields",
    demands: { collectionTargets: ["custom_fields"] },
  },
  {
    endpoint: "/app/setup/dimensions",
    demands: { collectionTargets: ["dimensions"] },
  },
  {
    endpoint: "/app/setup/file-drive",
    demands: { collectionTargets: ["company_configs"] },
  },
  {
    endpoint: "/app/setup/indices",
    demands: { collectionTargets: ["indexes"] },
  },
  {
    endpoint: "/app/setup/indices/create",
    demands: { collectionTargets: ["indexes"] },
  },
  {
    endpoint: "/app/setup/integrations",
    demands: { collectionTargets: ["integrationconfigs"] },
  },
  {
    endpoint: "/app/setup/members",
    demands: {
      collectionTargets: ["access_role_permissions", "invites", "users"],
    },
  },
  {
    endpoint: "/app/setup/number-series",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/setup/overview",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/setup/reports",
    demands: { collectionTargets: ["companies"] },
  },
  {
    endpoint: "/app/superadmin",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/companies",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/configurations",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/crons",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/developers",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/email-templates",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/languages",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/mailgun-logs",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/translations",
    demands: { superAdmin: true },
  },
  {
    endpoint: "/app/superadmin/warnings",
    demands: { superAdmin: true },
  },
];

export const routePathsThatNeedsCreateRights: RoutePath[] = [
  "/app/contracts/:contractId/invoiceplans/create",
  "/app/contracts/contracttypes/create",
  "/app/contracts/create",
  "/app/operations/customers/create",
  "/app/product-and-plans/articles/create",
  "/app/setup/indices/create",
];

export default routePathsWithPermission;
