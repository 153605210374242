import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useOnSubmit from "src/hooks/useOnSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ContractWithPermissionsAndTotals } from "src/accurasee-backend-types/app/contracts/contract.types";
import { LinearProgress } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { getStructure } from "./ContractTableStructure";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useGetContractsQuery,
  useDeleteContractMutation,
} from "src/redux/services/ContractService";
import getSummaries from "../../../../utils/getSummaries";

interface ContractTableProps {
  contractFilter: any;
}

const ContractsTable = ({ contractFilter }: ContractTableProps) => {
  const [t] = useTranslation();
  const location = useLocation();

  const [deleteContract] = useDeleteContractMutation();
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: contractTypesQuery, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const { data: customersQuery, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersQuery?.data;

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const onSubmit = useOnSubmit({
    apiMutations: { delete: deleteContract },
    name: "contract",
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    options,
    data: contracts,
    isLoading: isLoadingContracts,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractWithPermissionsAndTotals>({
    dataName: "contracts",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        pageSize: 15,
        sort: contractFilter?.sort,
        sortBy: contractFilter?.sortBy,
      },
      match: {
        status: contractFilter.status,
      },
    },
    filterOptions: {
      isRowSelectable: (data, dataIndex: number) => {
        return data.length !== dataIndex;
      },
      selectableRows: "single",
      searchPlaceholder: `${t("Search for")} ${t(
        "Description",
      ).toLowerCase()}, ${t("Number").toLowerCase()}, ${t(
        "Status",
      ).toLowerCase()} ${t("or")} ${t("Customer name").toLowerCase()}...`,
      deleteFunction: (e: any): void | false => {
        const contract = contracts[e.data[0].dataIndex];
        if (!contract.permissions.deleteRights) {
          enqueueSnackbar(
            `${t("Could not delete - deletion rights are missing")}`,
            {
              variant: "error",
            },
          );
          return false;
        } else if (contract._id) {
          onSubmit({ data: { delete: contract._id } });
        }
      },
    },
    useGetDataQuery: useGetContractsQuery,
  });

  const { idSummaries, dataWithSummaries: contractsWithSummaries } =
    getSummaries({
      data: contracts,
      sumTextKey: "projectExternalId",
      keys: [
        { name: "totalInvoiced" },
        { name: "totalScheduled" },
        { name: "totalInvoicedCurrentYear" },
        { name: "totalScheduledCurrentYear" },
        { name: "contractFeatures.totalPrice" },
      ],
    });

  const structure = getStructure({
    extraProps: {
      contractTypes,
      currencyCode: company?.currencyCode,
      customers,
      location: location as unknown as Location,
      idSummaries,
      users,
    },
    t,
  });

  let contractColumns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contractsWithSummaries,
      disabled: true,
      item,
    }),
  );

  // if (isLoadingCompany || isLoadingContractType || isLoadingUsers) {

  if (
    isLoadingCompany ||
    isLoadingContracts ||
    isLoadingContractType ||
    isLoadingCustomers ||
    isLoadingUsers
  ) {
    return <LinearProgress />;
  }

  return (
    <MuiDataTableWrapper
      data={contractsWithSummaries}
      columns={contractColumns}
      isFetching={isFetching || isLoadingContracts}
      options={options}
      tableType="main-with-filter"
      noBackground={true}
      fontSizeCells={"14px"}
    />
  );
};

export default ContractsTable;
